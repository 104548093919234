import createTheme from "@mui/material/styles/createTheme";

export const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'FC Iconic',
    ].join(','),
  },
  palette: {
    
  }
});
