import { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "./components/Loading";
import Routes from "./Routes";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes />
      </Router>
    </Suspense>
  );
}

export default App;
