import { useState, useMemo, useEffect, useCallback } from "react";
import cookie from "js-cookie";
import postMessage from "../helpers/PostMessage";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (cookie.get("token")) {
      setIsAuth(true);
    }
    const userProfile = cookie.get("userProfile");
    if (userProfile) {
      setUserProfile(JSON.parse(userProfile));
    }
  }, []);

  const login = useCallback(
    async message => {
      try {
        await logoutSuccess();
        const { accessToken, userProfile } = message.data;
        setUserProfile(userProfile);
        cookie.set("token", accessToken);
        cookie.set("userId", userProfile.userId);
        setIsAuth(true);
        cookie.set("userProfile", JSON.stringify(userProfile));
        if (window.location.pathname === "/profile") {
          navigate("/");
        }
      } catch (error) {}
    },
    [navigate]
  );

  const logout = async () => {
    try {
      postMessage({
        type: "logout"
      });
    } catch (error) {}
  };

  const logoutSuccess = async () => {
    cookie.remove("token");
    cookie.remove("tokenDate");
    cookie.remove("userId");
    setIsAuth(false);
    setUserProfile({});
  };

  useEffect(() => {
    setTimeout(() => {
      postMessage({
        type: process.env.REACT_APP_SESSION_EXPIRED
      });
    }, 5000);
  }, [])

  const value = useMemo(
    () => ({ isAuth, login, logout, userProfile, logoutSuccess }),
    [isAuth, userProfile, login]
  );
  return value;
};

export default useAuth;
