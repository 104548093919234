const PostMessage = data => {
  try {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return window.ReactNativeWebView.postMessage(JSON.stringify(data), "*");
    }
    return window.postMessageForAndroid(JSON.stringify(data));
  } catch (error) {
    return null;
  }
};

export default PostMessage;
