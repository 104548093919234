import { lazy, useEffect, useCallback } from "react";
import { AuthContext } from "./contexts/auth";
import useAuth from "./useContexts/useAuth";
import { Routes, Route, useNavigate } from "react-router-dom";
import Loading from "./components/Loading";

const Profile = lazy(() => import("./pages/Profile"));
const Summary = lazy(() => import("./pages/Summary"));
const History = lazy(() => import("./pages/History"));
const Detail = lazy(() => import("./pages/Detail"));
const Approves = lazy(() => import("./pages/Approves"));
const Menu = lazy(() => import("./pages/Menu"));

function Root() {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleEventMessage = useCallback(
    async (event) => {
      try {
        if (event.isTrusted) {
          return;
        }
        const message = JSON.parse(event.data);
        switch (message.type) {
          case "login":
            auth.login(message);
            break;
          case "logout":
            auth.logoutSuccess();
            break;
          case "redirect":
            navigate(`${message.path}/${message.payId}`);
            break;
          default:
            break;
        }
      } catch (error) {}
    },
    [auth, navigate]
  );

  useEffect(() => {
    document.addEventListener("message", handleEventMessage, false);
    window.addEventListener("message", handleEventMessage, false);
    return () => {
      document.removeEventListener("message", handleEventMessage, false);
      window.removeEventListener("message", handleEventMessage, false);
    };
  }, [handleEventMessage]);

  return (
    <AuthContext.Provider value={auth}>
      {auth.isAuth ? (
        <Routes>
          <Route element={<Profile />} path="/profile" />
          <Route element={<Detail />} path="/history/:id" />
          <Route element={<History />} path="/history" />
          <Route element={<Approves />} path="/approval" />
          <Route element={<Detail />} path="/approval/:id" />
          <Route element={<Summary />} path="/summary" />
          <Route element={<Menu />} path="/" />
          <Route element={<Menu />} path="*" />
        </Routes>
      ) : (
        <Routes>
          <Route element={<Loading />} path="*" />
        </Routes>
      )}
    </AuthContext.Provider>
  );
}

export default Root;
